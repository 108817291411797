@layer base {
  .main {
  gap: var(--sk-space-40)
}

.title {
  margin-bottom: 0;
}

.experience {
  width: initial;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-grey-050);
}

.divider {
  margin: 0;
}
}