@layer base {
  .main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  width: var(--sk-space-40);
  height: var(--sk-space-8);
}

.firstStep {
  margin-right: var(--sk-space-4);
  border-top-left-radius: var(--sk-radius-rounded);
  border-bottom-left-radius: var(--sk-radius-rounded);
}

.secondStep {
  border-top-right-radius: var(--sk-radius-rounded);
  border-bottom-right-radius: var(--sk-radius-rounded);
}

.currentStep {
  background-color: var(--sk-color-green-500);
}

.completedStep {
  background-color: var(--sk-color-green-200);
}

.futureStep {
  background-color: var(--sk-color-grey-100);
}

}