@layer base {
  .title {
  display: flex;
  gap: var(--sk-space-8);
  align-items: center;
}

.select {
  margin: 0;
}

.select [class*="jds-Select__control"] {
  border-radius: var(--sk-radius-8) !important;
}

.select .viewMoreButton {
  padding: var(--sk-space-12) var(--sk-space-16);
}

.select .viewMoreButton > button {
  width: 100%;
}

.checkboxGroup {
  display: grid;
  align-items: center;
  margin: var(--sk-space-16) 0;
}

@media (--sk-medium-viewport) {
  .checkboxGroup {
    grid-template-columns: 1fr 1fr;
  }
}

.checkboxGroup__choice > label {
  width: 100%;
}

@media (--sk-small-viewport-only) {
  .tooltip {
    margin-left: auto;
  }

  .tooltip::after {
    transform: translateX(calc(-100% + var(--sk-Tooltip-arrow-border-distance)));
  }
}

@media (width < 380px) {
  .tooltip::after {
    max-width: calc(100vw - var(--sk-space-32) * 2);
  }
}

}