@layer base {
  .main {
  margin: var(--sk-space-24) 0;
}

.main a {
  color: var(--sk-color-grey-900);
}

@media (--sk-large-viewport) {
  .main {
    margin: var(--sk-space-32) 0;
  }
}

}