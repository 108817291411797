@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.main a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.main a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.main a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.main table {
  width: 100%;
  table-layout: fixed;
}

.main table,
.main td,
.main tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.main td {
  padding: var(--sk-space-8);
}

/* HTML sized tags */
.richTextIframe,
.richTextImg {
  max-width: 100%;
}
}