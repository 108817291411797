@layer base {
  .formStep {
  display: none;
  flex-direction: column;
  gap: var(--sk-space-32);
}

.formStep__visible {
  display: flex;
}

.formStep > .title {
  margin-bottom: var(--sk-space-16);
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.controls__inline {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-8);
}

.control__enlarge {
  flex: 1;
}

.control__tooltip {
  align-self: flex-end;
  margin-bottom: var(--sk-space-12);
}

@media (--sk-medium-viewport) {
  .ratingInput {
    flex-direction: row;
    justify-content: space-between;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--sk-space-40);
  gap: var(--sk-space-24);
}

@media (--sk-small-viewport-only) {
  .actions {
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .action {
    width: 100%;
  }
}
}