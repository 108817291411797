@layer base {
  .options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--sk-space-16);
}

@media (--sk-small-viewport-only) {
  .options {
    grid-template-columns: 1fr;
  }
}

}